
import {
  defineComponent
} from 'vue'
import ServiceApiSettingsForm from '@/components/pages/services/api/ServiceApiSettingsForm.vue'

export default defineComponent({
  components: {
    ServiceApiSettingsForm,
  },
})
