import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1349d4aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-card__title" }
const _hoisted_2 = { class: "info-card__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["info-card", {
      'info-card--bordered': _ctx.bordered,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "title", {}, () => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.titleClass ? _ctx.titleClass : 'headline-18')
        }, _toDisplayString(_ctx.title), 3)
      ], true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}