
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoCard',
  props: {
    title: {
      type: String,
    },
    titleClass: {
      type: String,
    },
    bordered: {
      type: Boolean,
    },
  },
})
