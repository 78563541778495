
import {
  defineComponent, ref
} from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useApiOpenModal from '@/compositions/services/useApiOpenModal'
import InfoCard from '@/components/shared/templates/InfoCard.vue'

export default defineComponent({
  components: {
    TmFormLine,
    TmButton,
    InfoCard,
  },
  props: {
    legacy: {
      type: Boolean,
    },
  },
  setup() {
    const {
      openDeliveryCallbackFailed,
      openDeliveryCallbackSuccess,
      openGenerateNewApiKeyPassword,
    } = useApiOpenModal()

    const deliveryNotifications = ref('')
    const incomingMessages = ref('')

    const callbackOptions = ref(['multipart/form-data', 'application/x-www-form-urlencoded', 'JSON'])
    const callback = ref('multipart/form-data')

    const ipListsOptions = ref(['212.123.66.54', '12.93.220.187', '12.93.120.187'])
    const ipLists = ref(['212.123.66.54', '12.93.220.187', '12.93.120.187'])

    return {
      deliveryNotifications,
      incomingMessages,
      callbackOptions,
      callback,
      ipListsOptions,
      ipLists,
      openDeliveryCallbackFailed,
      openDeliveryCallbackSuccess,
      openGenerateNewApiKeyPassword,
    }
  },
})
