import { useModals } from '@/compositions/modals'

type UseApiOpenModal = () => {
  openDeliveryCallbackFailed: () => void
  openDeliveryCallbackSuccess: () => void
  openGenerateNewApiKeyPassword: () => void
  openGenerateNewApiKey: () => void
  openDeleteKeyModal: () => void
  openDeleteVerifyModal: () => void
}

const useApiOpenModal: UseApiOpenModal = () => {
  const { openModal } = useModals()

  const openDeliveryCallbackFailed = () => {
    openModal('confirmation', {
      title: 'Delivery callback URL test',
      text: [
        'No callback URL specified.',
        { text: 'Please enter a valid callback URL and try again.', color: 'red' },
      ],
      textContainer: 'div',
      btnText: 'Finish',
      hideCancelButton: true,
    })
  }

  const openDeliveryCallbackSuccess = () => {
    openModal('confirmation', {
      title: 'Delivery callback URL test',
      text: [
        'HTTP/1.1 404 Not Found',
        'Date: Tue, 21 Jun 2022 08:24:18 GMT',
        'Server: .V13 Apache',
        'Transfer-Encoding: chunked',
        'Content-Type: text/html',
      ],
      textContainer: 'div',
      btnText: 'Finish',
      hideCancelButton: true,
    })
  }

  const openGenerateNewApiKey = () => {
    openModal('singleInput', {
      modalTitle: 'Generate new API key',
      label: 'Your app name',
      placeholder: 'Enter your app name',
      btnText: 'Generate new key',
    })
  }

  const openGenerateNewApiKeyPassword = () => {
    openModal('singleInput', {
      modalTitle: 'Generate new API key',
      label: 'Your Textmagic account password',
      placeholder: 'Enter password',
      btnText: 'Generate',
      message: 'For security purposes, you need to confirm your API password change by entering your Textmagic account password.',
      type: 'password',
    })
  }

  const openDeleteKeyModal = () => {
    openModal('confirmation', {
      title: 'Delete API key',
      text: 'Are you sure want to delete this API Key? This will result in immediate deletion without the ability to undo the action.',
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  const openDeleteVerifyModal = () => {
    openModal('confirmation', {
      title: 'Delete log',
      text: 'Are you sure want to delete this Verify API log? This action cannot be undone later.',
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  return {
    openDeliveryCallbackFailed,
    openDeliveryCallbackSuccess,
    openGenerateNewApiKeyPassword,
    openGenerateNewApiKey,
    openDeleteKeyModal,
    openDeleteVerifyModal,
  }
}

export default useApiOpenModal
